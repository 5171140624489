<template>
<div>
	<v-card class="mx-auto mt-1 mb-8"
					v-if="step==1"
					max-width="450">


		<v-img height="250"
					 v-if="detail.ville != undefined"
					 :src="'https://media.berlcoin.fr/'+detail.ville.toLowerCase()+'.jpg'"></v-img>



		<v-card-text>


			<div class="my-1 mb-6 text-subtitle-1">
				{{detail.adresse}} {{detail.ville}}
			</div>


			<h3 :style="detail.solde > 0 ? 'color:green' : 'color:red'"
					v-html="monetary_aspect(detail.solde)+' &nbsp; Fractions disponibles'" />

			<h4 class="mt-8 mb-3">
				Indexation
			</h4>


			<table style="width:100%">

				<tr>
					<td>Date <br /> <b>{{detail.date_maj}}</b></td>
					<td>Cours <br /><b v-html="monetary_aspect(parseFloat(detail.valeur_maj))" /></td>
				</tr>
				<tr>
					<td>Anticipation<br /><b v-html="monetary_aspect(detail.anticipation)" /></td>
					<td>Valeur actuelle<br /> <b v-html="monetary_aspect(cours_actuel)" /></td>
				</tr>

			</table>

		</v-card-text>
		<v-card-title>Société d'investissement</v-card-title>
		<v-card-text>

			<b> {{ detail.raison_sociale}}</b>
			<br /> {{ detail.ppadresse}}
			{{ detail.ppcode_postal}} {{ detail.ppville}}
			<br />Siren : {{ detail.siren}}
		</v-card-text>



		<div v-if="detail.solde > 0 ">
			<v-card-title>Investir</v-card-title>

			<v-card-text>
				<v-text-field v-model="clepublique"
											style="font-size:60%"
											label="Clé publique">
				</v-text-field>

				<v-text-field v-model="montant"
											label="Montant en euros"></v-text-field>

				Pour <b>{{fraction}}</b> fractions
			</v-card-text>
			<v-card-title> Question antispam</v-card-title>
			<v-card-text>
				Votre année de naissance
				<v-otp-input v-model="naissance"
										 length="4"></v-otp-input>

			</v-card-text>

			<v-card-actions>

				<div @click="souscrire()"
						 v-if="clepublique.length > 30 & naissance.length ==4"
						 style="background-color:green; color:white"
						 class="btn  success mx-auto">Envoyer le contrat</div>

				<a style="background-color:purple; color:white"
					 href="https://wallet.fraction.re"
					 v-else
					 class="btn m-auto py-1 mb-3  "> Identification KYC/AML</a>

			</v-card-actions>
		</div>
	</v-card>
	<v-card class="mx-auto mt-1 mb-8 p-3"
					v-if="step==2"
					max-width="450">
		<h3>Votre contrat vous a été envoyé</h3>
		<p>Nous vous remercions de participer à l'écosystème immobilier Fraction.re</p>
		<p>Votre investissement est réalisé directement et sans intermédiaire avec :
			<br />
			<br /> <b> {{ detail.raison_sociale}}</b>
			<br /> {{ detail.ppadresse}}
			{{ detail.ppcode_postal}} {{ detail.ppville}}
			<br />Siren : {{ detail.siren}}
		</p>
		Le prix d'achat des fractions est garantie 4 jours ouvrables.
		<v-card-actions>
			<a class="btn mt-8 mb-5 mx-auto"
				 style="background-color:green;color:white"
				 href="/investir">Retour aux investissements </a>
		</v-card-actions>
	</v-card>
</div>
</template>



<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
	style: 'currency',
	currency: 'EUR',
	minimumFractionDigits: 2
});


export default {
	name: 'souscrire',
	components: {},
	props: {},
	data: () => ({
		clepublique: "",
		montant: 10,
		fraction: 0,
		naissance: "",
		detail: [],
		cours_actuel: undefined,
		step: 1
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.property();
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		id() {

			return this.$route.params.id;
		}
	},
	methods: {
		souscrire() {
			this.opt = [];
			this.opt["api"] = "write_contract"
			this.opt["debug"] = true
			this.opt["cle"] = this.clepublique
			this.opt["montant"] = this.montant
			this.opt["antispam"] = this.naissance
			this.opt["token_asset_code"] = this.id
			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.step = 2;
			})

		},
		property() {
			let opt = [];
			opt["api"] = "detail_property"
			opt["debug"] = true
			opt["asset"] = this.id

			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined) {

					this.detail = response.data.data

					let date1 = new Date(this.detail.date_maj);
					let date2 = new Date();
					let Diff_temps = date2.getTime() - date1.getTime();
					let Diff_jours = Diff_temps / (1000 * 3600 * 24);


					this.cours_actuel = parseFloat(this.detail.valeur_maj) + (Diff_jours * (parseFloat(this.detail.valeur_maj) * parseFloat(this.detail.anticipation) / 100) / 365);
					this.fraction = this.montant / this.cours_actuel;
				}
			})

		}
	},
	watch: {
		id() {
			this.detail()
		},
		montant() {
			this.fraction = this.montant / this.cours_actuel;
			if (parseFloat(this.fraction) > parseFloat(this.detail.solde)) {
				this.montant = Math.floor(this.detail.solde * this.cours_actuel * 100) / 100;
				this.fraction = this.detail.solde;
			}
		}
	}
}
</script>

<style scoped>
</style>
